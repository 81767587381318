import React, { useContext, useEffect, useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Img from 'gatsby-image'
import MAINLAYOUT from '../components/layouts/mainLayout.jsx'
import BOTTOM_MENU from '../components/bottom_menu/bottom_menu.jsx'
import {
  GlobalAuthContext,
  GlobalCategoriesContext,
} from '../context/GlobalContextProvider'
import { Auth } from 'aws-amplify'

const profilePage = () => {
  const { signOut } = useContext(GlobalAuthContext)
  const { getAuthenticatedUser } = useContext(GlobalCategoriesContext)
  const [userData, setUserData] = useState(void 0)
  const { logoGray, logout } = useStaticQuery(graphql`
    query {
      logoGray: file(relativePath: { eq: "img/logo_gray.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logout: file(relativePath: { eq: "img/logout.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    getAuthenticatedUser()
      .then(() => {
        Auth.currentUserInfo()
          .then(response => {
            setUserData(response)
          })
          .catch(err => {
            navigate('/')
          })
      })
      .catch(() => {
        navigate('/')
      })
  }, [])

  return (
    <MAINLAYOUT>
      <BOTTOM_MENU />
      {userData && userData.attributes ? (
        <>
          <div className="mx-auto max-w-bottomMenu">
            <div className="block pt-6 mx-auto mb-4" style={{ width: '250px' }}>
              <Img fluid={logoGray.childImageSharp.fluid} />
            </div>
            <div className="grid grid-cols-1 ">
              <div className="mb-5">
                <div>Email</div>
                <div className="text-xl font-bold leading-6">
                  {userData.attributes.email}
                </div>
              </div>
              <div className="flex items-center mt-5 gap-2 grid grid-cols-logout">
                <button style={{ width: '32px' }} onClick={() => signOut()}>
                  <Img fluid={logout.childImageSharp.fluid} />
                </button>
                <div>logout</div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </MAINLAYOUT>
  )
}

export default profilePage
